/**
* Copyright (C) 2016-present The ISLE Authors
*
* The isle-dashboard program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the
* License, or (at your option) any later version.
*
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
* GNU Affero General Public License for more details.
*
* You should have received a copy of the GNU Affero General Public License
* along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

// EXPORTS //

export const ADD_ENROLLED_NAMESPACE = 'ADD_ENROLLED_NAMESPACE';
export const ADD_ERROR_NOTIFICATION = 'ADD_ERROR_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const APPEND_CREATED_NAMESPACE = 'APPEND_CREATED_NAMESPACE';
export const AUTHENTICATED = 'AUTHENTICATED';
export const CHANGED_NAMESPACE = 'CHANGED_NAMESPACE';
export const COMPUTED_COMPLETIONS = 'COMPUTED_COMPLETIONS';
export const CREATED_ANNOUNCEMENT = 'CREATED_ANNOUNCEMENT';
export const CREATED_CUSTOM_FIELD = 'CREATED_CUSTOM_FIELD';
export const CREATED_BACKUP = 'CREATED_BACKUP';
export const CREATED_METRIC = 'CREATED_METRIC';
export const CREATED_ROLE = 'CREATED_ROLE';
export const CREATED_TICKET = 'CREATED_TICKET';
export const DELETED_BACKUP = 'DELETED_BACKUP';
export const DELETED_CURRENT_NAMESPACE = 'DELETED_CURRENT_NAMESPACE';
export const DELETED_CUSTOM_FIELD = 'DELETED_CUSTOM_FIELD';
export const DELETED_EVENT = 'DELETED_EVENT';
export const DELETED_FILE = 'DELETED_FILE';
export const DELETED_LESSON = 'DELETED_LESSON';
export const DELETED_METRIC = 'DELETED_METRIC';
export const DELETED_PROGRAM = 'DELETED_PROGRAM';
export const DELETED_ROLE = 'DELETED_ROLE';
export const DELETED_TICKET = 'DELETED_TICKET';
export const DELETED_USER = 'DELETED_USER';
export const DELETED_ANNOUNCEMENT = 'DELETED_ANNOUNCEMENT';
export const DISABLED_TFA = 'DISABLED_TFA';
export const EDITED_ANNOUNCEMENT = 'EDITED_ANNOUNCEMENT';
export const ENABLED_TFA = 'ENABLED_TFA';
export const FIELD_POSITION_DECREMENTED = 'FIELD_POSITION_DECREMENTED';
export const FIELD_POSITION_INCREMENTED = 'FIELD_POSITION_INCREMENTED';
export const GET_ALL_COHORTS = 'GET_ALL_COHORTS';
export const GET_ALL_FILES = 'GET_ALL_FILES';
export const GET_ALL_LESSONS = 'GET_ALL_LESSONS';
export const GET_ALL_NAMESPACES = 'GET_ALL_NAMESPACES';
export const GET_ALL_PROGRAMS = 'GET_ALL_PROGRAMS';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_BACKUPS = 'GET_BACKUPS';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_ALL_TICKETS = 'GET_ALL_TICKETS';
export const GET_COMPLETION_LESSON_REFS = 'GET_COMPLETION_LESSON_REFS';
export const GET_COMPLETION_TAGS = 'GET_COMPLETION_TAGS';
export const GET_COMPLETION_COMPONENTS = 'GET_COMPLETION_COMPONENTS';
export const GET_COURSE_TICKETS = 'GET_COURSE_TICKETS';
export const GET_CUSTOM_FIELDS = 'GET_CUSTOM_FIELDS';
export const GET_CUSTOM_TRANSLATIONS = 'GET_CUSTOM_TRANSLATIONS';
export const GET_OVERVIEW_STATISTICS = 'GET_OVERVIEW_STATISTICS';
export const GET_HISTORICAL_OVERVIEW_STATISTICS = 'GET_HISTORICAL_OVERVIEW_STATISTICS';
export const GET_REQUEST_STATISTICS = 'GET_REQUEST_STATISTICS';
export const GET_ROOMS = 'GET_ROOMS';
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_PUBLIC = 'GET_SETTINGS_PUBLIC';
export const GET_TEMPLATE_LESSONS = 'GET_TEMPLATE_LESSONS';
export const GET_TFA_QRCODE = 'GET_TFA_QRCODE';
export const GET_USERS = 'GET_USERS';
export const GET_USER_TICKETS = 'GET_USER_TICKETS';
export const LESSON_ORDER = 'LESSON_ORDER';
export const LESSON_ORDER_DIRECTION = 'LESSON_ORDER_DIRECTION';
export const RECEIVED_FILES = 'RECEIVED_FILES';
export const RECEIVED_LICENSE = 'RECEIVED_LICENSE';
export const RECEIVED_NAMESPACE_FILES = 'RECEIVED_NAMESPACE_FILES';
export const RECEIVED_TOKEN = 'RECEIVED_TOKEN';
export const REMOVED_LICENSE = 'REMOVED_LICENSE';
export const REQUEST_TFA = 'REQUEST_TFA';
export const RETRIEVED_BADGES = 'RETRIEVED_BADGES';
export const RETRIEVED_COHORTS = 'RETRIEVED_COHORTS';
export const RETRIEVED_COMPLETION_RULES = 'RETRIEVED_COMPLETION_RULES';
export const RETRIEVED_LESSONS = 'RETRIEVED_LESSONS';
export const RETRIEVED_PUBLIC_LESSONS = 'RETRIEVED_PUBLIC_LESSONS';
export const SAVED_LESSON_METRICS = 'SAVED_LESSON_METRICS';
export const SEARCH_PHRASE_SET = 'SEARCH_PHRASE_SET';
export const TICKET_CLOSED = 'TICKET_CLOSED';
export const TICKET_OPENED = 'TICKET_OPENED';
export const TICKET_MESSAGE_ADDED = 'TICKET_MESSAGE_ADDED';
export const TICKET_PRIORITY_UPDATED = 'TICKET_PRIORITY_UPDATED';
export const TRIGGERED_EVENT = 'TRIGGERED_EVENT';
export const UPDATED_LESSON = 'UPDATED_LESSON';
export const UPDATED_METRIC = 'UPDATED_METRIC';
export const UPDATED_OWNED_NAMESPACE = 'UPDATED_OWNED_NAMESPACE';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_UPDATED_BY_ADMIN = 'USER_UPDATED_BY_ADMIN';
export const USER_PICTURE_MODIFIED = 'USER_PICTURE_MODIFIED';
export const USER_RECEIVED_BADGES = 'USER_RECEIVED_BADGES';
export const UPDATED_CUSTOM_FIELD = 'UPDATED_CUSTOM_FIELD';
export const UPDATED_ROLE = 'UPDATED_ROLE';
export const UPDATED_SETTINGS = 'UPDATED_SETTINGS';
export const UPDATED_STUDENT_PROGRESS = 'UPDATED_STUDENT_PROGRESS';
export const UPDATED_TRANSLATIONS = 'UPDATED_TRANSLATIONS';
export const RETRIEVED_ENROLLABLE_COHORTS = 'RETRIEVED_ENROLLABLE_COHORTS';
